import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bounds from 'react-bounds';
import { ViewPager, Frame, Track, View } from 'react-view-pager';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { margins, mobileThresholdPixels, mobileThreshold } from '../styledComponents';
import routesMap from '../../Routes';

import arrowLeft from '../../assets/partners/arrow-left.png';
import arrowRight from '../../assets/partners/arrow-right.png';
import maduraLogo from '../../assets/partners/madura.png';
import degresLogo from '../../assets/partners/85degres.png';
import admiseLogo from '../../assets/partners/admise_paris.png';
import apcLogo from '../../assets/partners/apc.png';
import artumescoLogo from '../../assets/partners/artumes&co.png';
import hastLogo from '../../assets/partners/hast_paris.png';
import isabelMarantLogo from '../../assets/partners/Isabel-Marant.png';
import jessicaChoayLogo from '../../assets/partners/jessica_choay.png';
import laureDeSagazanLogo from '../../assets/partners/Laure_de_Sagazan.svg';
import luzLogo from '../../assets/partners/luz.png';
import stephanieWolffLogo from '../../assets/partners/stephanie_wolff.png';
import magazellesLogo from '../../assets/partners/magazelles.svg';
import violetteSauvageLogo from '../../assets/partners/violette_sauvage.png';
import carolinaRitzlerLogo from '../../assets/partners/CarolinaRitzler.png';
import sezaneLogo from '../../assets/partners/sezane.png';

// cSpell:ignore tilly
const partnerImages = [
  { index: 1, img: maduraLogo, alt: 'conciergerie paris' },
  { index: 2, img: apcLogo, alt: 'couturiers certifiés' },
  { index: 3, img: sezaneLogo, alt: 'meilleure retoucherie Paris' },
  { index: 4, img: laureDeSagazanLogo, alt: 'retouche de robe de mariée' },
  { index: 5, img: degresLogo, alt: 'tilly couture' },
  { index: 6, img: admiseLogo, alt: 'retouches de tailleur et costume' },
  { index: 7, img: artumescoLogo, alt: 'cintrer une veste' },
  { index: 8, img: hastLogo, alt: 'couturiere a domicile tarif' },
  { index: 9, img: jessicaChoayLogo, alt: 'retoucheur' },
  { index: 10, img: luzLogo, alt: 'couturiere paris' },
  { index: 11, img: stephanieWolffLogo, alt: 'conciergerie paris' },
  { index: 12, img: magazellesLogo, alt: 'retouche habit' },
  { index: 13, img: carolinaRitzlerLogo, alt: 'service retouche Paris' },
  { index: 14, img: violetteSauvageLogo, alt: 'meilleure retouche paris' },
  { index: 15, img: isabelMarantLogo, alt: 'prestataire de retouche' },
];

const Img = styled.img`
  max-width: 200px;
  max-height: 200px;

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 100px;
    max-height: 100px;
  }
`;

const frameStyle = {
  width: '80%',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 150px;
  }
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 40px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 15px;
  }
`;

class PartnersPager extends Component {
  static bounds() {
    return {
      mobile: {
        minWidth: 0,
        maxWidth: mobileThreshold,
      },
      desktop: {
        minWidth: mobileThreshold + 1,
      },
    };
  }

  render() {
    const viewsToShow = typeof window !== 'undefined' ? Math.ceil(window.innerWidth / 350) : 5;
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <ControlImg
          alt="Partenaire précédent de service de retouches"
          src={arrowLeft} onClick={() => this.track.prev()}
        />
        <Frame className="frame" style={frameStyle}>
          <Track
            ref={(c) => { this.track = c; }}
            viewsToShow={viewsToShow}
            infinite
            className="track"
          >
            {partnerImages.map(partnerImage =>
              <View className="view" key={partnerImage.index}>
                <Link to={routesMap.PartnersPage.url} style={{ textDecoration: 'none' }}>
                  <ImgContainer>
                    <Img alt={partnerImage.alt} src={partnerImage.img} /></ImgContainer>
                </Link>
              </View>,
            )}
          </Track>
        </Frame>
        <ControlImg
          alt="Partenaire suivant de retouches à domicile"
          src={arrowRight} onClick={() => this.track.next()}
        />
      </ViewPager>
    );
  }
}

PartnersPager.propTypes = {
  isBound: PropTypes.func.isRequired,
};

export default bounds.wrap(PartnersPager);

import React from 'react';
import PropTypes from 'prop-types';
import bounds from 'react-bounds';
import { ViewPager, Frame, Track, View } from 'react-view-pager';
import styled from 'styled-components';

import { margins, mobileThresholdPixels, mobileThreshold } from '../styledComponents';
import { fontSizes, colors } from '../home/v2/StyledComponents';

import header1 from '../../assets/partners/header1.jpg';
import header2 from '../../assets/partners/header2.jpg';
import header1mobile from '../../assets/partners/header1mobile.jpg';
import header2mobile from '../../assets/partners/header2mobile.jpg';
import arrowLeft from '../../assets/partners/arrow-left.png';
import arrowRight from '../../assets/partners/arrow-right.png';

const H2 = styled.h2`
  color: ${colors.navy};
  font-family: Gotham;
  margin: 0px;
  text-align: left;
  font-size: 55px;
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
  }
`;

const partnerImages = [
  { index: 1,
    img: header1,
    imgMobile: header1mobile,
    alt: 'divisez par 2 votre taux de retour',
    text: (
      <H2>
        ÷ par 2<br />
        votre taux<br />
        de retour
      </H2>
    ),
  },
  { index: 2,
    img: header2,
    imgMobile: header2mobile,
    alt: 'en bichonnant vos clients',
    text: (
      <H2>
        en<br />
        bichonnant<br />
        vos clients
      </H2>
    ),
  },
];

const Img = styled.img`
  width: 100vw;
`;

const frameStyle = {
  width: '100vw',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const ControlContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 1;
  ${props => props.right && 'right:0px;'}
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 70px;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 20px;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 80vw;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${margins.x2l};
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 50px;
  }
`;

class PartnersHeaderPager extends React.Component {
  static bounds() {
    return {
      mobile: {
        minWidth: 0,
        maxWidth: mobileThreshold,
      },
      desktop: {
        minWidth: mobileThreshold + 1,
      },
    };
  }
  render() {
    const { isBound } = this.props;
    return (
      <ViewPager tag="main" style={viewPagerStyle}>
        <Frame className="frame" style={frameStyle}>
          <ControlContainer>
            <ControlImg
              alt="Header précédent service de couture"
              src={arrowLeft} onClick={() => this.track.prev()}
            />
          </ControlContainer>
          <Track
            ref={(c) => { this.track = c; }}
            viewsToShow={1}
            className="track"
          >
            {partnerImages.map(partnerImage =>
              <View className="view" key={partnerImage.index}>
                <Img alt={partnerImage.alt} src={isBound('mobile') ? partnerImage.imgMobile : partnerImage.img} />
                <TextContainer>
                  {partnerImage.text}
                </TextContainer>
              </View>,
            )}
          </Track>
        </Frame>
        <ControlContainer right>
          <ControlImg
            alt="Header suivant boutique retoucherie partenaire"
            src={arrowRight} onClick={() => this.track.next()}
          />
        </ControlContainer>
      </ViewPager>
    );
  }
}

PartnersHeaderPager.propTypes = {
  isBound: PropTypes.func.isRequired,
};

export default bounds.wrap(PartnersHeaderPager);
